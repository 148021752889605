import React from 'react'
import { useRelative } from '../hooks/useRelative'
import { useMenuData } from '../hooks/useMenuData'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import { Link } from 'gatsby'
import { Visible } from 'react-grid-system'

const setSpanStagger = () => {
	let styles = '';

	for (let i = 0; i < 20; i += 1) {
		styles += `
			&:nth-child(${i}) span {
				transition-delay: calc(0.1s + ${i} * 0.05s);
			}
		`
	}

	return css`${styles}`;
}

const ChildWrapper = styled.div`
	margin-top: 25px;
	opacity: 0;
	transition: opacity 0.1s ${props => props.theme.eases.out};
`
const NavigationItemLink = styled(Link)`
	display: block;
	color: ${props => props.theme.colors.green};
	font-size: 18px;
	line-height: 23px;
	padding: 5px 0;
	transition: color 0.4s ${props => props.theme.eases.out};
	overflow: hidden;

	span {
    display: inline-block;
    position: relative;
		transform: translateY(105%);
		opacity: 0;
		transition:
			transform 0s ${props => props.theme.eases.out} 0.1s,
			opacity 0.1s ${props => props.theme.eases.out};
	}

	&:hover,
	&.active {
		color: ${props => props.theme.colors.yellow};
	}
`
const NavigationStyled = styled.div`
	pointer-events: none;
	background-color: white;
	padding-left: 4.16vw;
	padding-right: 4.16vw;
	padding-top: 25px;
	padding-bottom: 25px;
	transform: scaleY(0);
	transform-origin: left top;
	transition: transform 0.5s ${props => props.theme.eases.out};
	border: 1px solid ${props => props.theme.colors.gray};
	border-top: 0;
	box-sizing: content-box;

	${up('sm')} {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	${props => props.toggle && `
		pointer-events: all;
		transform: scaleY(1);

		${NavigationItemLink} {
			${setSpanStagger()};

			span {
				opacity: 1;
				transform: translateY(0);
				transition:
					transform 0.4s ${props.theme.eases.out},
					opacity 0s;
			}
		}

		${ChildWrapper} {
			opacity: 1;
			transition: opacity 0.4s ${props.theme.eases.out} 0.65s;
		}
	`}

`

const Navigation = (props) => {
	const toggle = props.toggle
	const menuData = useMenuData()
	const headerData = menuData.nodes.find(menu => menu.locations[0] === 'PRIMARY_NAVIGATION')
	const items = headerData && headerData.menuItems.nodes

	return (
		<NavigationStyled toggle={toggle}>
			{items && items.map((item, index) => <MenuItem key={`item-${index}`} {...item} />)}
			<Visible xs><ChildWrapper>{props.children}</ChildWrapper></Visible>
		</NavigationStyled>
	)
}

const MenuItem = ({ label, url }) => {
	const relativeUrl = useRelative(url)

	return (
		<NavigationItemLink to={relativeUrl} activeClassName={'active'}><span>{label}</span></NavigationItemLink>
	)
}

export default Navigation