import React, { useEffect } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import gsap from 'gsap'
import styled from 'styled-components'
import { useRef } from 'react'
import PanelTopSvg from '../../assets/images/panel-top.svg'
import PanelBottomSvg from '../../assets/images/panel-bottom.svg'

const PanelLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.yellow};
  pointer-events: none;
  z-index: 12;
  transform: translateY(100%);
`

const PanelTop = styled.div`
  position: absolute;
  bottom: 100%;
  width: 100%;
  line-height: 0;

  svg { width: 100%; }
`

const PanelBottom = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  line-height: 0;

  svg { width: 100%; }
`

const Panel = (props) => {
  const panel = useRef()
  const panelTop = useRef()
  const panelBottom = useRef()
  
  let panelHeight = 0

  const setDimensions = () => {
    panelHeight = panel.current.offsetHeight + panelTop.current.offsetHeight + panelBottom.current.offsetHeight

    gsap.set(panel.current, { y: panelHeight })
  }

  useEffect(() => {
    setDimensions()

    window.addEventListener('resize', setDimensions)
  })

  const handleEnter = () => {
    window.scroll && window.scroll.setScroll(0, 0)
    window.fromTransition = true
    window.currentCover = null
  }

  const handleEntered = () => {
    window.scroll && window.scroll.update()
    if (window.currentCover) window.currentCover.loadImage().then(enterAnimation)
    else enterAnimation()
  }

  const enterAnimation = () => {
    const tl = new gsap.timeline()

    tl.to(panel.current, {
      y: -panelHeight,
      ease: 'power3.inOut',
      duration: 1,
    })

    tl.to(panel.current, {
      duration: 0,
      onComplete: () => window.currentCover && window.currentCover.appear()
    }, '-=0.5')
  }

  const handleExit = () => {
    gsap.fromTo(panel.current, {
      y: panelHeight
    }, {
      y: 0,
      ease: 'power3.inOut',
      duration: 0.8
    })
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <Transition
          key={props.location.pathname}
          timeout={{
            enter: 0,
            exit: 800,
           }}
          onExit={handleExit}
          onEnter={handleEnter}
          onEntered={handleEntered}
        >
          {props.children}
        </Transition>
      </SwitchTransition>
      <PanelLayer ref={panel}>
        <PanelTop ref={panelTop}><PanelTopSvg/></PanelTop>
        <PanelBottom ref={panelBottom}><PanelBottomSvg/></PanelBottom>
      </PanelLayer>
    </>
  )
}

export default Panel