import React, { useRef, useEffect, useState } from 'react'
import Navigation from './Navigation'
import { Link } from 'gatsby'
import { checkMobile } from '../../helpers'
import styled, { css } from 'styled-components'
import Logo from '../../assets/images/logo.svg'
import MenuLogo from '../../assets/images/menu.svg'
import PhoneIcon from '../../assets/images/phone.svg'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import { up } from 'styled-breakpoints'
import useRelative from '../hooks/useRelative'

const height = 80
const styles = css`
	position: relative;
  overflow: hidden;
  z-index: 1;

  span {
    display: flex;
		align-items: center;
    height: 100%;
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.4s ${props => props.theme.eases.out};
  }

  &::before {
    content: '${props => props.text}';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(30px);
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.25s ${props => props.theme.eases.out};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: bottom center;
    transform: scaleY(0);
    transition: transform 0.6s ${props => props.theme.eases.out};
  }

	body:not(.isMobile) &:hover {
    span {
      opacity: 0;
      transform: translateY(-30px);
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.25s ${props => props.theme.eases.out};
    }

    &::before {
      transform: translateY(0);
      opacity: 1;
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.4s ${props => props.theme.eases.out};
    }

    &::after { transform: scaleY(1); }
	}
`

const HeaderWrapper = styled.header`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background: transparent;
	z-index: 10;
	pointer-events: none;
	transform: translateY(0);
	transition: transform 0.75s ${props => props.theme.eases.out};

	${props => !props.show && `transform: translateY(-${height}px);` }
`
const LogoWrapper = styled(Link)`
	pointer-events: all;
	position: relative;
	background-color: ${props => props.theme.colors.green};
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	width: 100%;
	height: ${height}px;

	svg {
		width: 100px;
		height: auto;

		${up('sm')} {
			width: 125px;
			height: 40px;
		}
	}
`
const TogglerWrapper = styled.div`
	pointer-events: all;
	outline: none;
	position: relative;
	cursor: pointer;
	background-color: ${props => props.theme.colors.yellow};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: white;
	width: 100%;
	height: ${height}px;

	${styles}

	&::after {
		background-color: ${props => props.theme.colors.gray};
	}

	&::before {
		text-transform: uppercase;
		letter-spacing: 2px;
		color: ${props => props.theme.colors.green};
	}

	svg {
		width: 60px;
		height: 16px;
	}
`
const TelWrapper = styled.a`
	pointer-events: all;
	position: relative;
	background-color: ${props => props.theme.colors.yellow};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-left: auto;
	height: ${height}px;
	text-align: center;

	${up('sm')} { width: 80px; }

	&:hover svg {
		animation-name: shake;
		animation-duration: 0.08s;
		animation-iteration-count: infinite;
	}

	svg {
		width: 26px;
		height: 26px;

		@keyframes shake {
			0% { transform: translateX(0) }
			50% { transform: translateX(-1px) }
			100% { transform: translateX(2px) }
		}
	}
`
const ContactWrapper = styled(Link)`
	pointer-events: all;
	position: relative;
	background-color: ${props => props.theme.colors.green};
	color: white;
	width: 100%;
	height: ${height}px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 15px;

	${styles}

	${up('xl')} { padding: 0 40px; }

	&::after {
		background-color: ${props => props.theme.colors.greenLight};
	}

	&::before {
		padding: 0 30px;
    box-sizing: border-box;

		${up('xl')} {
			padding: 0 40px;
		}
	}
`

const Header = (props) => {
	const header = useRef(null)
	const smartHeader = props.smartHeader

	const isMobile = checkMobile()

	const [isShowing, setShow] = useState(true)
	const [menuOpen, setMenuOpen] = useState(false)

	let currentScroll = 0

	const relativeRdvUrl = useRelative(process.env.GATSBY_PAGE_RDV)
	
	useEffect(() => {
		window.addEventListener('customScrollEvent', handleScroll)

		return () => window.addEventListener('customScrollEvent', handleScroll)
	}, [])

	const handleScroll = (e) => {
		if (!smartHeader) return

		const offset = window.innerHeight
		const lastScroll = currentScroll ? currentScroll : 0

		currentScroll = isMobile ? window.pageYOffset : e.detail.y

		if (currentScroll < offset) setShow(true)
		else {
				if (lastScroll > currentScroll) setShow(true)
				if (lastScroll < currentScroll) setShow(false)
		}
	}

	const onBurgerClick = () => {
		setMenuOpen(!menuOpen)
		window.scroll && menuOpen ? window.scroll.start() : window.scroll.stop()
	}

	return(
		<HeaderWrapper ref={header} show={isShowing}>
			<Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
				<Row nogutter>
					<Col xs={12} sm={5} lg={3}>
						<LogoWrapper to="/" aria-label="Accueil">
							<Logo/>
						</LogoWrapper>
					</Col>
					<Col xs={12} sm={5} lg={3}>
						<TogglerWrapper text='Menu' onClick={onBurgerClick} onKeyDown={onBurgerClick} role="button" aria-label="Menu" tabIndex={0}>
							<span><MenuLogo/></span>
						</TogglerWrapper>
					</Col>
					<Hidden xs>
						<Col xs={6} sm={4} lg={2} offset={{sm: 6,lg: 13}} style={{textAlign: 'right'}}>
							<TelWrapper href={ `tel:${process.env.GATSBY_PHONE}` }>
								<PhoneIcon/>
							</TelWrapper>
						</Col>
						<Col xs={12} sm={4} lg={3}>
							<ContactWrapper to={ relativeRdvUrl } text='Prendre rendez-vous'><span>Prendre rendez-vous</span></ContactWrapper>
						</Col>
					</Hidden>
					<Col xs={24} sm={10} lg={6}>
						<Navigation toggle={menuOpen}>
							<TelWrapper href={ `tel:${process.env.GATSBY_PHONE}` }><PhoneIcon/></TelWrapper>
							<ContactWrapper to={ relativeRdvUrl } text='Prendre rendez-vous'><span>Prendre rendez-vous</span></ContactWrapper>
						</Navigation>
					</Col>
				</Row>
			</Container>
		</HeaderWrapper>
	)
}

export default Header
