import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
    padding: 0 !important;
    margin: 0 !important;
    font-family: ${props => props.theme.fonts.content};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    &.overflow-hidden { overflow: hidden; }
  }

  * { box-sizing: border-box; }
  a { text-decoration: none; }

  .yellow {
    text-transform: uppercase;
    color: ${props => props.theme.colors.yellow};

    font-size: 18px;
    line-height: 23px;
  }

  h1, h2 {
    font-family: ${props => props.theme.fonts.headings};
    font-style: normal;
    font-weight: bold;
  }

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
  }

  .has-text-align-center {
    text-align: center;
  }
`

export default GlobalStyle