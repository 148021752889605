import React from 'react'
import FooterNavigation from './FooterNavigation'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Text from '../text'

const FooterWrapper = styled.footer`
	position: relative;
	margin-top: -1px;
`

const FooterTop = styled.div`
	background-color: ${props => props.theme.colors.green};
	padding: 90px 0;
`

const ItemTitle = styled(Text)`
	color: white;
	opacity: 0.3;
	text-transform: uppercase;
	margin-bottom: 15px;

	${up('lg')} { margin-bottom: 30px; }
`

const ItemContent = styled(Text)`
	color: white;

	p { margin: 0; }

	a {
		display: block;
		color: white;
		transition: color 0.4s ${props => props.theme.eases.out};

		&:hover {
			color: ${props => props.theme.colors.yellow};
		}
	}
`

const Item = styled.div`
	position: relative;
	margin-bottom: 30px;

	${up('lg')} { margin: 0; }
`

const FooterBottom = styled.div`
	background-color: ${props => props.theme.colors.greenLight};
	padding: 20px 0;
`

const FooterItem = ({ title, children }) => (
	<Item>
		<ItemTitle tag='div' size='12'>{title}</ItemTitle>
		<ItemContent tag='div' size='24'>{children}</ItemContent>
	</Item>
)

const Footer = () => (
	<FooterWrapper data-scroll-section>
		<FooterTop>
			<Container fluid>
				<Row>
					<Col lg={6} xxl={5} offset={{lg: 2}}>
						<FooterItem title={ 'Adresse' }>
							<a target='_blank' rel="noreferrer" href={ process.env.GATSBY_ADDRESS_LINK } dangerouslySetInnerHTML={{__html: process.env.GATSBY_ADDRESS}}/>
						</FooterItem>
					</Col>
					<Col lg={5} offset={{lg: 2, xxl: 3}}>
						<FooterItem title={ 'Contact' }>
							<a href={ `tel:${process.env.GATSBY_PHONE}` }>{ process.env.GATSBY_PHONE }</a>
							<a href={ `mailto:${process.env.GATSBY_MAIL}` }>{ process.env.GATSBY_MAIL }</a>
						</FooterItem>
					</Col>
					<Col lg={5} offset={{lg: 3}}>
						<FooterItem title={ 'Réseaux sociaux' }>
							<a target='_blank' rel="noreferrer" href={ process.env.GATSBY_FACEBOOK }>Facebook</a>
							<a target='_blank' rel="noreferrer" href={ process.env.GATSBY_LINKEDIN }>Linkedin</a>
							<a target='_blank' rel="noreferrer" href={ process.env.GATSBY_INSTAGRAM }>Instagram</a>
						</FooterItem>
					</Col>
				</Row>
			</Container>
		</FooterTop>
		<FooterBottom>
				<FooterNavigation />
		</FooterBottom>
	</FooterWrapper>
)

export default Footer
