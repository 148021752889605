import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const TextStyled = styled.div`
  // ----- MOBILE SIZE -----
  ${props => props.size === '500' && `
    font-size: 100px;
    line-height: 100px;
  `}

  ${props => props.size === '160' && `
    font-size: 40px;
    line-height: 40px;
  `}

  ${props => props.size === '120' && `
    font-size: 60px;
    line-height: 90px;
  `}

  ${props => props.size === '100' && `
    font-size: 25px;
    line-height: 40px;
  `}

  ${props => props.size === '72' && `
    font-size: 30px;
    line-height: 50px;
  `}

  ${props => props.size === '40' && `
    font-size: 30px;
    line-height: 40px;
  `}

  ${props => props.size === '40key' && `
    font-size: 20px;
    line-height: 25px;
  `}

  ${props => props.size === '32' && `
    font-size: 25px;
    line-height: 33px;
  `}

  ${props => props.size === '24' && `
    font-size: 20px;
    line-height: 30px;
  `}

  ${props => props.size === '18' && `
    font-size: 18px;
    line-height: 19px;
  `}

  ${props => props.size === '16' && `
    font-size: 16px;
    line-height: 20px;
  `}

  ${props => props.size === '14' && `
    font-size: 14px;
    line-height: 18px;
  `}

  ${props => props.size === '12' && `
    font-size: 12px;
    line-height: 15px;
  `}

 // ----- IPAD SIZE -----
  ${up('sm')} {
    ${props => props.size === '160' && `
      font-size: 60px;
      line-height: 60px;
    `}

    ${props => props.size === '120' && `
      font-size: 85px;
      line-height: 115px;
    `}

    ${props => props.size === '100' && `
      font-size: 50px;
      line-height: 76px;
    `}
  }

  ${props => props.size === '40key' && `
    font-size: 25px;
    line-height: 30px;
  `}

  ${props => props.size === '24' && `
    font-size: 24px;
    line-height: 30px;
  `}

  // ----- IPAD PRO SIZE -----
  ${up('md')} {
    ${props => props.size === '500' && `
      font-size: 200px;
      line-height: 200px;
    `}

    ${props => props.size === '40' && `
      font-size: 40px;
      line-height: 50px;
    `}
  }

  // ----- HD SIZE -----
  ${up('lg')} {
    ${props => props.size === '500' && `
      font-size: 300px;
      line-height: 300px;
    `}

    ${props => props.size === '160' && `
      font-size: 110px;
      line-height: 110px;
    `}

    ${props => props.size === '120' && `
      font-size: 120px;
     line-height: 150px;
    `}

    ${props => props.size === '100' && `
      font-size: 80px;
      line-height: 106px;
    `}

    ${props => props.size === '72' && `
      font-size: 50px;
      line-height: 70px;
    `}

    ${props => props.size === '40key' && `
      font-size: 40px;
      line-height: 50px;
    `}

    ${props => props.size === '32' && `
      font-size: 32px;
      line-height: 40px;
    `}
  }

  // ----- HD MACBOOK SIZE -----
  ${up('xl')} {
    ${props => props.size === '500' && `
      font-size: 350px;
      line-height: 350px;
    `}

    ${props => props.size === '160' && `
      font-size: 130px;
      line-height: 130px;
    `}

    ${props => props.size === '100' && `
      font-size: 80px;
      line-height: 106px;
    `}
  
    ${props => props.size === '72' && `
      font-size: 72px;
      line-height: 90px;
    `}
  }

  // ----- FULL HD SIZE -----
  ${up('xxl')} {
    ${props => props.size === '500' && `
      font-size: 500px;
      line-height: 450px;
    `}

    ${props => props.size === '100' && `
      font-size: 100px;
      line-height: 126px;
    `}

    ${props => props.size === '160' && `
      font-size: 160px;
      line-height: 160px;
    `}
  }
`

const Text = (props) => <TextStyled as={props.tag} {...props}>{props.children}</TextStyled>

export default Text