import { useStaticQuery, graphql } from 'gatsby'

export const useMenuData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query getNavigations {
        wordpress {
          menus {
            nodes {
              locations
              menuItems {
                nodes {
                  id
                  label
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return wordpress.menus
}