function checkMobile() {
  if (typeof window === 'undefined') return
  
  let isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

  if (isMobile) {
    document.body.classList.add('isMobile')
    document.documentElement.classList.add('isMobile')
  } else if (document.body.classList.contains('isMobile')) {
    document.body.classList.remove('isMobile')
    document.documentElement.classList.remove('isMobile')
  }

  return isMobile
}

function lerp(value1, value2, amount) {
  let amountValue = amount

  amountValue = amountValue < 0 ? 0 : amountValue
  amountValue = amountValue > 1 ? 1 : amountValue

  return (1 - amountValue) * value1 + amountValue * value2
}

function getExcerpt(content, nbchars) {
  return (content.length > nbchars) ? `${content.substring(0, nbchars)}...` : content
}

function stripHtml(content) {
  return content.replace(/(<([^>]+)>)/gi, "")
}

export {
  checkMobile,
  lerp,
  getExcerpt,
  stripHtml
}