import React from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const CookieWrapper = styled.div`
  .cookie-notice {
    z-index: 10;
    position: fixed;
    padding: 30px;

    right: 30px;
    bottom: 30px !important;
    width: 260px;
    
    background-color: ${props => props.theme.colors.yellow};

    & > div:not(.cookie-notice__text) {
      display: flex;
      justify-content: space-between;
    }

    &__text {
      font-size: 13px;
      color: black;
      text-align: left;
    }

    &__accept,
    &__decline {
      outline: none;
      font-size: 10px;
      margin-top: 30px;
      font-family: ${props => props.theme.fonts.content};
      text-transform: uppercase;
      padding: 10px;
      background-color: black;
      color: ${props => props.theme.colors.yellow};
      cursor: pointer;
      border: 1px solid black;
      transition:
        background-color 0.5s ${props => props.theme.eases.out},
        color 0.5s ${props => props.theme.eases.out};

      &:hover {
        background-color: ${props => props.theme.colors.yellow};
        color: black;
      }
    }
  }
`

const Cookie = (props) => {
  return (
    <CookieWrapper>
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        declineButtonText="Je n'accepte pas"
        cookieName="gatsby-gdpr"
        disableStyles={true}
        containerClasses="cookie-notice"
        contentClasses="cookie-notice__text"
        buttonClasses="cookie-notice__accept"
        declineButtonClasses="cookie-notice__decline"
        enableDeclineButton={true}>
        Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.
      </CookieConsent>
    </CookieWrapper>
  )
}


export default Cookie