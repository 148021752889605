export default {
  breakpoints: {
    sm: '768px',
    md: '1024px',
    lg: '1200px',
    xl: '1400px',
    xxl: '1600px'
  },
  colors: {
    green: '#00381f',
    greenLight: '#00522d',
    black: '#000',
    gray: '#f2f2f2',
    grayDark: '#e0e0e0',
    yellow: '#ffc914',
    yellowDark: '#b38d0e'
  },
  fonts: {
    content: `'Circular Std Book', 'sans-serif'`,
    headings: `'Circular Std', 'sans-serif'`,
  },
  eases: {
    out: `cubic-bezier(0.23, 1, 0.32, 1)`,
    inOut: `cubic-bezier(0.86, 0, 0.07, 1)`,
    outBack: `cubic-bezier(0.175, 0.885, 0.32, 1.275)`
  },
  indexes: {
    indexCookieNotice: 10,
    indexHeader: 10
  }
}