import React, { Component } from 'react'
import { TransitionContext } from '../../context'
import Panel from './Panel'

export default class Transition extends Component {
  render() {
    return (
			<>
        <Panel {...this.props}>{this.props.children}</Panel>
			</>
    )
  }
}

Transition.contextType = TransitionContext