import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import { ContextLoader } from '../../context'
import LogoSvg from '../../assets/images/logo.svg'
import styled from 'styled-components'

const LoaderWrapper = styled.div`
	position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.green};
  z-index: 15;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 200px;
		height: 200px;
	}
`

const Loader = () => {
	const loaderEl = useRef(null)
	const [isLoading, updateIsLoading] = useContext(ContextLoader)

	let loaderHeight = 0

	useEffect(() => {
			hideLoader().then(() => {
				updateIsLoading(false)
			})
	}, [updateIsLoading])

  const hideLoader = () => {
		return new Promise((resolve) => {
			const tl = gsap.timeline({
				onComplete: () => {
					loaderEl.current.style.display = 'none'
					resolve()
				}
			})

			tl.to(loaderEl.current, {
				delay: 1,
				alpha: 0,
				duration: 0.6,
				ease: 'power3.out'
			})
		})
  }

	return (
		<LoaderWrapper ref={loaderEl}>
			<LogoSvg/>
		</LoaderWrapper>
	)
}

export default Loader