import React from 'react'
import { Link } from 'gatsby'
import { useMenuData } from '../hooks/useMenuData'
import { useRelative } from '../hooks/useRelative'
import styled from 'styled-components'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import { up } from 'styled-breakpoints'

const FooterLink = styled(Link)`
	display: inline-block;
	color: white;
	padding: 10px 0;
	font-size: 14px;
	line-height: 16px;
	transition: color 0.6s ${props => props.theme.eases.out};

	${up('lg')} {
		padding: 15px 0;
		font-size: 10px;
		line-height: 13px;
	}

	&:hover,
	&.active { color: ${props => props.theme.colors.yellow}; }
`

const FooterLinkUpper = styled(FooterLink)`
	text-transform: uppercase;
`

const MenuItem = ({ label, url }) => {
	const relativeUrl = useRelative(url)

	return (
		<FooterLinkUpper activeClassName='active' to={relativeUrl}>{label}</FooterLinkUpper>
	)
}

const FooterNavigation = () => {
	const menuData = useMenuData()
	const footerData = menuData.nodes.find(menu => menu.locations[0] === 'FOOTER_MENU')
	const items = footerData && footerData.menuItems.nodes

	const pageMentions = useRelative(process.env.GATSBY_PAGE_MENTIONS)

	return (
		<>
			{items &&
				<div>
					<Container fluid>
						<Row>
							<Col lg={15} offset={{lg: 1}}>
								<Row>
									{items.map((item, index) => <Col xs="content" key={index}><MenuItem {...item} /></Col>)}
								</Row>
							</Col>
							<Col lg={6} offset={{lg: 1}}>
								<Hidden lg xl xxl>
									<Row>
										<Col xs="content"><FooterLink to="https://digital-cover.fr/" target="_blank" rel="noopener noreferrer">Création de site internet</FooterLink></Col>
										<Col xs="content"><FooterLink to={ pageMentions }>Mentions légales</FooterLink></Col>
									</Row>
								</Hidden>
								<Hidden xs sm md>
									<Row justify='end'>
										<Col xs="content"><FooterLink to="https://digital-cover.fr/" target="_blank" rel="noopener noreferrer">Création de site internet</FooterLink></Col>
										<Col xs="content"><FooterLink to={ pageMentions }>Mentions légales</FooterLink></Col>
									</Row>
								</Hidden>
							</Col>
						</Row>
					</Container>
				</div>
			}
		</>
	)
}

export default FooterNavigation