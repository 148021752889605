import React, { useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import LocomotiveScroll from 'locomotive-scroll'
import { ContextLoader } from '../../context'
import { checkMobile } from '../../helpers'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
	html {
		&:not(.isMobile) { overflow: hidden; }
		&.has-scroll-dragging { user-select: none; }
	}

	body {
		width: 100%;
		height: 100vh;

		&:not(.isMobile) {
			overflow: hidden;
			width: 100vw;
			height: 100vh;
		}

		&.overflow-hidden { overflow: hidden; }
	}

	*[data-scroll-section] {
		will-change: transform;
		backface-visibility: hidden;
	}

	.js-scroll {
		overflow: hidden;
		position: relative;
		width: 100vw;
	}
`

const Scroll = (props) => {

	const isMobile = checkMobile()

  // Check if everything is loaded, from Loader component
  const [isLoading] = useContext(ContextLoader)

	// Scroll variables
	const scrollEl = useRef(null)

	// Init scroll
	useEffect(() => {
		if (isLoading === true) return

		const smoothScroll = new LocomotiveScroll({
			el: scrollEl.current,
			smooth: true,
      passive: true,
      inertia: 1.0
		})

		window.scroll = smoothScroll
    
		if (!isMobile) smoothScroll.on('scroll', handleScroll)
		if (!isMobile) smoothScroll.on('call', (eventName) => window.dispatchEvent(new CustomEvent(eventName)))

		return () => smoothScroll.destroy()
	}, [isLoading, isMobile])

	const handleScroll = e => {
		const newScrollEvent = new CustomEvent('customScrollEvent', {
			'detail': { 'y': e.delta.y }
		})

		window.dispatchEvent(newScrollEvent)
	}

	return (
		<>
			<GlobalStyle/>
			<div
				className="scroll js-scroll"
				ref={scrollEl}
				data-scroll-container
				data-scroll-section
				id="fixed-elements-target"
			>
				{props.children}
			</div>
		</>
	)
}

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Scroll